<template>
  <div>
    <validationObserver v-slot="{ handleSubmit }">
      <b-form @submit.prevent="handleSubmit(changePass)" class="curva-form mb-5 p-5">
      <b-row class="justify-content-center">
        <b-col lg="6" class="pt-3">
          <b-row>
            <b-col lg="12" class="mb-1">
              <input-form :type="showPassword?'text':'password'" :placeholder="$t('forms.currentPass')"
                          v-model="info.old_password"
                          :validate="'required'" :name="`Current password`"/>
            </b-col>
            <b-col lg="12" class="mb-1">
              <input-form :type="showPassword?'text':'password'" :placeholder="$t('forms.newPass')"
                          v-model="info.password"
                          :validate="'required'" :name="`New password`"/>
            </b-col>
            <b-col lg="12" class="mb-4">
              <input-form :type="showPassword?'text':'password'" :placeholder="$t('forms.confirmationPass')"
                          v-model="info.password_confirmation"
                          :validate="'required'" :name="`confirm password`"/>
            </b-col>
            <b-col lg="12" class="text-right">
              <b-button class="grey-btn small-rounded-btn mx-3" @click="$router.push({name:'profileInfo'})">{{
                  $t('forms.discard') }}</b-button>
              <b-button variant="warning" type="submit" class="curva-granola-btn small-rounded-btn" v-if="!loading">
                <span class="font-size-16">{{ $t('forms.save') }}</span>
              </b-button>
              <b-button variant="warning" class="grey-btn small-rounded-btn mr-3" v-else>
                <spinner-loading :text="$t('login.checking')" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
    </validationObserver>
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import profileServics from '../services/profile.services'
export default {
  data () {
    return {
      info: {
        old_password: '',
        password: '',
        password_confirmation: ''
      },
      loading: false,
      showPassword: false
    }
  },
  methods: {
    resetForm () {
      this.info = {
        old_password: '',
        password: '',
        password_confirmation: ''
      }
    },
    changePass () {
      this.loading = true
      profileServics.changePassword(this.info).then(res => {
        core.showSnackbar('success', res.data.message)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  }
}
</script>
